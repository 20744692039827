<template>
  <v-container>

    <h1 align="center" class="mb-2">SERITEC HOME</h1>

    <v-row>
      <v-col cols="6">
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToRicercaVerbale()">Crea <br> - <br> Ricerca <br> verbale</v-btn>
      </v-col>
      <v-col cols="6">
        <!-- RIMOSSO (Sia verbali recenti che esportazione verbali non vengono utilizzati)
          <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" 
        light @click="goToVerbaliRecenti()">Verbali <br> recenti</v-btn>
       -->
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToScadenziarioVerificheRidotto()">Scadenziario <br> verifiche </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <!-- RIMOSSO Da ultima chiamata con francesca        
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToEsportazioneVerbali()">Esportazione <br> verbali</v-btn> 
        -->
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToModificaFirma()">Modifica <br> firma</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToVeicoliAziendali()">Rendicontazione <br> viaggi <br> - <br> Carburante</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToPianificazioneAttivita()">Pianificazione <br> - <br> chiusura <br> attività</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToGoogleMaps()">Google <br> Maps</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
          @click="goToSmartcal()">Calendario</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="red" class="white--text" height="50px" width="100%" size="x-large" light @click="logout()">
          Logout
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>


</template>

<script>
/*eslint-disable */

import { Conf } from "../conf/defaults";
import mixin from "../mixin/MixingCommonComp";


export default {
  mixins: [mixin],

  data() {
    return {

    }
  },
  methods: {
    goToRicercaVerbale() {
      this.$router.push("/ricercaVerbale");
    },
    goToScadenziarioVerificheRidotto(){
      this.$router.push("/scadenziarioVerificheRidotto");
    },
    /* RIMOSSO PER INUTILIZZO (francesca)
      goToVerbaliRecenti() {
      this.$router.push("/recenti");
    },*/
    /* RIMOSSO PER INUTILIZZO (ultima chiamata con francesca)
    goToEsportazioneVerbali() {
      this.$router.push("/esportazioneVerbali");
    },*/
    goToModificaFirma() {
      this.$router.push("/firma");
    },
    goToVeicoliAziendali() {
      this.$router.push("/veicoli");
    },
    goToPianificazioneAttivita() {
      this.$router.push("/pianificazioneAttivita");
    },
    goToGoogleMaps() {
      this.$router.push("/googleMaps");
    },
    goToSmartcal() {
      this.$router.push("/smartcal")
    },
    logout() {
      this.$session.clear();
      this.$session.destroy();
      this.$router.push({ path: "/Login/" });
    }
  },
  computed: {
    buildNo: function () {
      return Conf.buildNo.replaceAll("-", "");
    },
  },
}

</script>