<template>
    <v-container fluid>
      <v-row justify="end">
        <v-col>
          <v-btn text @click="getEvents"><v-icon>mdi-refresh</v-icon>Aggiorna</v-btn>
        </v-col>
        <v-col class="text-center">
          <v-btn icon @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn-toggle v-model="calendarType" mandatory>
            <v-btn value="month">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
            <v-btn value="week">
              <v-icon>mdi-calendar-week</v-icon>
            </v-btn>
            <v-btn value="day">
              <v-icon>mdi-calendar-today</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-select 
            outlined 
            dense 
            label="Stato" 
            :items="projectStatuses" 
            item-text="name" 
            item-value="value" 
            v-model="projectStatus">
          </v-select>
        </v-col>
        <v-col class="text-center">
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" v-if="calendarType == 'month'">
              <h4>
                {{ month }}
              </h4>
            </v-btn>
            <v-btn text v-bind="attrs" v-on="on" v-else-if="calendarType == 'week'">
              <h4>
                {{ week }}
              </h4>
            </v-btn>
            <v-btn text v-bind="attrs" v-on="on" v-else-if="calendarType == 'day'">
              <h4>
                {{ day }}
              </h4>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            {{ date }}
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date); getEvents()">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col></v-col>
      </v-row>
      <br />
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="1" v-if="!hideCalendars">
          <v-flex rounded class="calendar-border">
            <v-checkbox label="Tutti" class="minified-checkbox" hide-details v-model="selectAll"></v-checkbox>
            <v-checkbox v-for="calendar in calendars" :key="calendar.ad_user_id" v-model="selectedCalendars"
              :value="calendar" :label="calendar.name" class="minified-checkbox" hide-details :color="calendar.color">
            </v-checkbox>
          </v-flex>
        </v-col>
        <v-col :cols="calendarCols"><v-calendar :type="calendarType" :weekdays="[1, 2, 3, 4, 5, 6]"
            :events="filteredEvents" ref="calendar" v-model="date" id="calendar" class="noselect" :event-color="getColor"
            :event-more="false" @mousedown:event="startDrag" @mousedown:time="startTime" @mousedown:day="startTime"
            @mousemove:time="mouseMove" @mousemove:day="mouseMove" @mouseup:time="endDrag" @mouseup:day="endDrag"
            @mouseleave.native="cancelDrag">
            <template v-slot:event="{ event, timed, timeSummary }">
              <b>{{ event.name }}</b>, {{ timeSummary() }}, {{ event.username }}
              <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
            </template> </v-calendar></v-col>
      </v-row>
      <Event ref="event"></Event>
    </v-container>
  </template>
  
  <script>
  /* eslint-disable */
  import mixin from "../mixin/MixingCommonComp";
  import colors from "../components/colors";
  import Event from "../components/Event.vue";
  
  export default {
    mixins: [mixin],
    components: { Event },
    name: "Home",
    data() {
      return {
        calendarTypes: [
          { name: "Mensile", value: "month" },
          { name: "Settimanale", value: "week" },
          { name: "Giornaliero", value: "day" },
        ],
        calendarType: "month",
        events: [],
        date: new Date().toISOString().substring(0, 10),
        calendars: [],
        selectedCalendars: [],
        originalEvent: null,
        dragEvent: null,
        dragged: false,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        newEvent: false,
        value: "",
        modal: false,
        projectStatus: "A",
        projectStatuses:[
          { name: "Tutti", value: "A"},
          { name: "Programmato", value: "S"},
          { name: "Completato", value: "C"}          
        ]
      };
    },
    methods: {
      next() {
        this.$refs.calendar.next();
        this.getEvents();
      },
      prev() {
        this.$refs.calendar.prev();
        this.getEvents();
      },
      getEvents() {
        let dateStart = new Date(this.date);
        dateStart.setDate(-30);
        let dateEnd = new Date(this.date);
        dateEnd.setDate(dateEnd.getDate() + 50);
        let parameters = {};
        parameters.data = {
          dateStart: dateStart,
          dateEnd: dateEnd,
        };
        parameters.idempiereRestPath = "vecos/getEvents";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          this.events = response.data.events;
          /*for(let i in this.events){
            this.events[i].start = new Date(this.events[i].start).toISOString().replace("T"," ").substring(0, 19)
            this.events[i].end = new Date(this.events[i].end).toISOString().replace("T"," ").substring(0, 19)

          }*/
          console.log(this.events)
        });
      },
      getCalendars() {
        let parameters = {};
        parameters.data = {};
        parameters.idempiereRestPath = "vecos/getCalendars";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          this.calendars = response.data.calendars;
          this.calendars.forEach((element, index) => {
            element.color = colors[index];
          });
          this.selectedCalendars = this.calendars;
        });
      },
      getColor(event) {
        let calendar = this.calendars.find((item) => {
          return item.ad_user_id == event.ad_user_id;
        });
        return calendar ? calendar.color : "primary";
      },
      moveEvent(event) {
        if (!event) {
          return;
        }
  
        for (let item in this.events) {
          if ((event.a_asset_id != 0 && event.fc_scheduledjobuser_id != this.events[item].fc_scheduledjobuser_id) &&
            (event.a_asset_id == this.events[item].a_asset_id && event.start == this.events[item].start)) {
            this.showErrorDialog("Impossibile spostare l'evento, lo strumento è già in uso in quell'orario")
            return this.getEvents();
          }
        }
  
        let parameters = {};
        parameters.data = {
          fc_scheduledjobuser_id: event.fc_scheduledjobuser_id,
          dateStart: event.start,
          dateStop: event.end,
        };
        parameters.idempiereRestPath = "vecos/moveEvent";
        this.$store.dispatch("callIdempiereRest", parameters);
      },
      // --- Inizio Metodi per il Drag an drop
      startDrag({ event }) {
        if (event) {
          this.originalEvent = { ...event };
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      },
      startTime(tms) {
        const mouse = this.toTime(tms);
  
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start;
  
          this.dragTime = mouse - start;
        } else {
          this.newEvent = true;
          this.createStart = this.roundTime(mouse);
          this.createEvent = {
            name: `Nuova Attività #${this.events.length}`,
            start: this.createStart,
            end: this.createStart,
            timed: true,
            username: "",
            ad_user_id: -1,
            users: [],
            projects: [],
            a_asset_id: 0,
          };
  
          this.events.push(this.createEvent);
        }
      },
      extendBottom(event) {
        this.createEvent = event;
        this.createStart = event.start;
        this.extendOriginal = event.end;
      },
      mouseMove(tms) {
        const mouse = this.toTime(tms);
  
        if (this.dragEvent && this.dragTime !== null) {
          this.dragged = true;
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;
  
          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false);
          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);
  
          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      },
      async endDrag() {
        if (
          this.dragEvent &&
          (this.dragEvent.start != this.originalEvent.start ||
            this.dragEvent.end != this.originalEvent.end)
        ) {
          this.moveEvent(this.dragEvent);
        } else if (this.dragEvent && !this.dragged) {
          await this.$refs.event.open(this.dragEvent);
          this.getEvents();
        } else if (this.newEvent) {
          console.log("createEvent");
          await this.$refs.event.open(this.createEvent);
          this.getEvents();
        } else {
          this.moveEvent(this.createEvent);
        }
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
        this.originalEvent = null;
        this.dragged = false;
        this.newEvent = false;
      },
      cancelDrag() {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal;
          } else {
            const i = this.events.indexOf(this.createEvent);
            if (i !== -1) {
              this.events.splice(i, 1);
            }
          }
        }
  
        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
        this.originalEvent = null;
        this.newEvent = false;
      },
      roundTime(time, down = true) {
        const roundTo = 15; // minutes
        const roundDownTime = roundTo * 60 * 1000;
  
        return down
          ? time - (time % roundDownTime)
          : time + (roundDownTime - (time % roundDownTime));
      },
      toTime(tms) {
        return new Date(
          tms.year,
          tms.month - 1,
          tms.day,
          tms.hour,
          tms.minute
        ).getTime();
      },
    },
    mounted() {
      this.getCalendars();
      this.getEvents();
    },
    computed: {
      filteredEvents() {
        let ad_user_ids = this.selectedCalendars.map((x) => x.ad_user_id);
        let events = this.events.filter((item) => {
          return ad_user_ids.includes(item.ad_user_id) || item.ad_user_id == -1;
        });

        if(this.projectStatus == "S"){
          events = events.filter((item) => !item.isCompleted);
        }else if (this.projectStatus == "C") {
          events = events.filter((item) => item.isCompleted);
        }

        return events;
      },
      hideCalendars() {
        return this.calendars.length <= 1;
      },
      calendarCols() {
        return this.hideCalendars ? 12 : 11;
      },
      month() {
        let monthNames = [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre",
        ];
        let date = new Date(this.date);
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();
        return month + " " + year;
      },
      week() {
        let current = new Date(this.date);
        var first = current.getDate() - current.getDay() + 1; // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
  
        let week =
          "Settimana dal " +
          this.dateToItalianFormat(
            new Date(current.setDate(first)).toISOString().substring(0, 10)
          );
        week +=
          " al " +
          this.dateToItalianFormat(
            new Date(current.setDate(last)).toISOString().substring(0, 10)
          );
        return week;
      },
      day() {
        let date = new Date(this.date);
        return this.dateToItalianFormat(date.toISOString().substring(0, 10));
      },
      selectAll: {
        get() {
          for (let calendar of this.calendars) {
            if (!this.selectedCalendars.includes(calendar)) {
              return false;
            }
          }
          return true;
        },
        set() {
          if (this.selectedCalendars.length < this.calendars.length) {
            this.selectedCalendars = this.calendars;
          } else {
            this.selectedCalendars = [];
          }
        },
      },
    },
  };
  </script>
  <style>
  .minified-checkbox {
    margin: 0;
  }
  
  .calendar-border {
    border-style: solid;
    border-width: 2px;
    border-color: #e0e0e0;
  }
  
  .v-calendar-daily__scroll-area {
    overflow-y: hidden !important;
  }
  
  #calendar {
    height: 180%;
  }
  
  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
  </style>
  <style scoped lang="scss">
  .v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;
  
    &::after {
      display: none;
      position: absolute;
      left: 50%;
      height: 4px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      width: 16px;
      margin-left: -8px;
      opacity: 0.8;
      content: "";
    }
  
    &:hover::after {
      display: block;
    }
  }
  </style>